import React, { useContext, useState } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './LanguageSwitch.css';

function LanguageSwitch() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);

  // 获取当前语言显示文本
  const getCurrentLanguageText = () => {
    switch(language) {
      case 'zh': return '中';
      case 'en': return 'EN';
      case 'ms': return 'MY';
      default: return '中';
    }
  };

  return (
    <div className="language-switch">
      <button className="language-icon" onClick={() => setIsOpen(!isOpen)}>
        {getCurrentLanguageText()}
      </button>
      {isOpen && (
        <ul className="language-dropdown">
          <li onClick={() => { setLanguage('zh'); setIsOpen(false); }} className={language === 'zh' ? 'active' : ''}>
            中文
          </li>
          <li onClick={() => { setLanguage('en'); setIsOpen(false); }} className={language === 'en' ? 'active' : ''}>
            English
          </li>
          <li onClick={() => { setLanguage('ms'); setIsOpen(false); }} className={language === 'ms' ? 'active' : ''}>
            Bahasa Melayu
          </li>
        </ul>
      )}
    </div>
  );
}

export default LanguageSwitch; 