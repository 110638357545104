export const translations = {
  zh: {
    nav: {
      features: '功能特点',
      portfolio: '案例展示',
      pricing: '价格方案',
      contact: '联系我们',
      startProject: '开始项目'
    },
    hero: {
      title: '构建专业网站的智能之选',
      subtitle: '从创意到上线，我们提供一站式网站建设解决方案',
      cta: '立即开始'
    },
    form: {
      title: '定制您的网站',
      websiteType: {
        title: '选择网站类型',
        business: { label: '企业官网', description: '展示企业形象与服务' },
        ecommerce: { label: '电子商务', description: '在线销售产品和服务' },
        portfolio: { label: '作品集展示', description: '展示个人或团队作品' },
        blog: { label: '个人博客', description: '分享文章和内容' },
        landing: { label: '营销落地页', description: '产品或服务推广' },
        custom: { label: '自定义方案', description: '定制化网站解决方案' }
      },
      content: {
        title: '内容需求',
        language: '网站语言',
        contentCreation: '内容创建',
        hasContent: '我有准备好的内容',
        needsContent: '需要协助创建内容'
      },
      basicInfo: {
        title: '基本信息',
        businessName: '企业/品牌名称',
        businessNamePlaceholder: '请输入您的企业或品牌名称',
        industry: '所属行业',
        industryPlaceholder: '请输入您的行业',
        targetAudience: '目标受众',
        targetAudiencePlaceholder: '描述您的目标客户群体'
      },
      budget: {
        title: '预算与时间',
        range: '预算范围',
        timeline: '期望完成时间',
        ranges: {
          basic: 'RM 3,000 - RM 5,000（基础建站）',
          standard: 'RM 5,000 - RM 10,000（标准方案）',
          premium: 'RM 10,000 - RM 20,000（高级定制）',
          business: 'RM 20,000 - RM 50,000（企业解决方案）',
          enterprise: 'RM 50,000 - RM 100,000（大型企业定制）',
          custom: 'RM 100,000 以上（全方位定制方案）',
          flexible: '灵活预算（可协商）'
        },
        timelines: {
          urgent: '尽快完成（1-2周）',
          normal: '正常进度（3-4周）',
          flexible: '时间灵活'
        }
      },
      contact: {
        title: '联系方式',
        name: '联系人姓名',
        namePlaceholder: '输入您的姓名',
        phone: '联系电话',
        phonePlaceholder: '请输入您的联系电话',
        email: '电子邮箱',
        emailPlaceholder: '请输入您的电子邮箱',
        preferredContact: '首选联系方式',
        contactMethods: {
          phone: '电话',
          email: '电子邮箱',
          whatsapp: 'WhatsApp'
        }
      },
      other: {
        title: '其他需求',
        placeholder: '请描述您的其他特殊需求或说明'
      },
      submit: '提交需求'
    },
    features: {
      title: '我们的优势',
      subtitle: '专业的网站建设服务，助力企业数字化转型',
      items: [
        {
          icon: '⚡',
          title: '快速交付',
          description: '7-14天快速交付，专业团队高效执行',
          stats: '90%',
          statsText: '的项目按时交付'
        },
        {
          icon: '🎨',
          title: '创意设计',
          description: '专业设计团队，打造独特品牌视觉',
          stats: '100+',
          statsText: '成功案例'
        },
        {
          icon: '📱',
          title: '全端适配',
          description: '完美适配所有设备，无缝用户体验',
          stats: '99%',
          statsText: '兼容性'
        },
        {
          icon: '🛠️',
          title: '一站式服务',
          description: '从域��护的全程技术支持',
          stats: '24/7',
          statsText: '技术支持'
        }
      ]
    },
    footer: {
      description: '专业的网站建设服务，助力企业数字化转型',
      quickLinks: '快速链接',
      features: '功能特点',
      portfolio: '案例展示',
      pricing: '价格方案',
      contact: '联系我们',
      newsletter: '订阅通讯',
      newsletterDesc: '订阅我们的通讯，获取最新优惠和动态',
      emailPlaceholder: '输入您的邮箱',
      subscribe: '订阅',
      address: '马来西亚吉隆坡市中心',
      rights: '保留所有权利',
      privacy: '隐私政策',
      terms: '使用条款',
      cookies: 'Cookie政策'
    },
    portfolio: {
      title: '成功案例',
      subtitle: '我们的一些精选项目展示',
      viewProject: '查看项目',
      categories: {
        ecommerce: '电子商务',
        business: '企业官网',
        portfolio: '作品集',
        blog: '博客',
        landing: '营销页面',
        custom: '定制方案'
      },
      items: [
        {
          title: '高端时尚电商平台',
          description: '为知名品牌打造的现代化电子商务网站，支持多语言和跨境支付'
        },
        {
          title: '企业品牌官网',
          description: '专业的企业形象展示网站，突出品牌价值和服务优势'
        },
        {
          title: '创意设计工作室',
          description: '独特的作品展示平台，展现设计师的创意理念'
        },
        {
          title: '生活方式博客',
          description: '清新简约的博客网站，分享生活灵感和故事'
        },
        {
          title: '产品营销落地页',
          description: '高转化率的营销页面，突出产品特点和价值'
        },
        {
          title: '定制化解决方案',
          description: '根据客户需求定制的特殊功能网站'
        }
      ]
    },
    pricing: {
      title: '价格方案',
      subtitle: '选择最适合您的方案，开启专业网站之旅',
      recommendedBadge: '推荐方案',
      monthly: '起',
      selectPlan: '选择方案',
      note: '需要更多定制？我们提供完全定制化的解决方案',
      customQuote: '获取定制报价',
      plans: {
        basic: {
          name: '基础版',
          description: '适合小型企业和个人站点',
          features: [
            '响应式网站设计',
            '5个页面内容',
            'SEO基础优化',
            '免费域名一年',
            '基础技术支持',
            '7天快速交付'
          ]
        },
        standard: {
          name: '标准版',
          description: '适合成长型企业和电商网站',
          features: [
            '高级响应式设计',
            '10个页面内容',
            'SEO完整优化',
            '免费域名和主机',
            '多语言支持',
            '在线支付集成',
            '优先技术支持',
            '14天交付'
          ]
        },
        premium: {
          name: '高级版',
          description: '适合大型企业和高定制需求',
          features: [
            '定制化设计',
            '无限页面',
            'SEO高级优化',
            '全套域名主机服务',
            '多语言多货币',
            '高级功能定制',
            '24/7技术支持',
            '专属项目经理',
            '30天交付'
          ]
        }
      }
    },
    contact: {
      title: '联系我们',
      subtitle: '随时与我们联系，我们期待为您服务',
      address: {
        title: '地址',
        value: '马来西亚吉隆坡市中心'
      },
      phone: {
        title: '电话',
        value: '+60 12-345 6789'
      },
      email: {
        title: '电子邮箱',
        value: 'contact@swiftsites.my'
      },
      hours: {
        title: '工作时间',
        value: '周一至周五 9:00 - 18:00'
      },
      form: {
        name: '姓名',
        namePlaceholder: '请输入您的姓名',
        email: '电子邮箱',
        emailPlaceholder: '请输入您的电子邮箱',
        phone: '电话',
        phonePlaceholder: '请输入您的联系电话',
        subject: '主题',
        subjectPlaceholder: '请输入咨询主题',
        message: '留言',
        messagePlaceholder: '请输入您的留言内容',
        submit: '发送留言',
        submitted: '已发送'
      },
      social: {
        title: '关注我们'
      }
    },
    terms: {
      title: '使用条款',
      acceptance: {
        title: '条款接受',
        description: '使用本网站即表示您同意这些条款。如果您不同意这些条款，请不要使用本网站。'
      },
      services: {
        title: '服务说明',
        description: '我们提供以下服务：',
        items: [
          '网站设计与开发',
          '网站维护与支持',
          '域名与主机服务',
          '网站优化服务'
        ]
      },
      payment: {
        title: '付款条款',
        description: '付款相关规定：',
        items: [
          '所有价格以马来西亚令吉特（RM）计价',
          '需支付50%预付款',
          '余款在项目完成后支付',
          '支持多种支付方式'
        ]
      },
      intellectual: {
        title: '知识产权',
        description: '所有网站内容的知识产权归SwiftSites所有，未经授权不得使用。'
      },
      termination: {
        title: '终止条款',
        description: '我们保留在任何时候终止服务的权利，特别是在违反使用条款的情况下。'
      },
      liability: {
        title: '责任限制',
        description: '在法律允许的范围内，我们不对使用本网站可能造成的任何损失承担责任。'
      },
      footer: '© 2024 SwiftSites. 保留所有权利。'
    },
    cookies: {
      title: 'Cookie政策',
      what: {
        title: '什么是Cookie',
        description: 'Cookie是存储在您设备上的小型文本文件，用于改善您的网站浏览体验。'
      },
      types: {
        title: 'Cookie类型',
        items: [
          {
            name: '必要Cookie',
            description: '网站基本功能所需的Cookie',
            duration: '会话期间'
          },
          {
            name: '功能Cookie',
            description: '记住您的偏好设置',
            duration: '1年'
          },
          {
            name: '分析Cookie',
            description: '帮助我们改进网站',
            duration: '2年'
          },
          {
            name: '营销Cookie',
            description: '用于个性化广告',
            duration: '90天'
          }
        ]
      },
      control: {
        title: 'Cookie控制',
        description: '您可以通过以下方式控制Cookie：',
        methods: [
          '浏览器设置',
          '隐私模式',
          'Cookie管理工具',
          '第三方插件'
        ]
      },
      thirdParty: {
        title: '第三方Cookie',
        description: '我们的网站使用以下第三方服务：',
        services: [
          'Google Analytics',
          'Facebook Pixel',
          'LinkedIn Insight',
          'HubSpot'
        ]
      },
      footer: '© 2024 SwiftSites. 保留所有权利。'
    },
    cookieConsent: {
      message: '我们使用 Cookie 来改善您的浏览体验。您可以选择接受所有 Cookie，或者只接受必要的 Cookie。',
      learnMore: '了解更多',
      acceptAll: '接受所有',
      acceptEssential: '仅接受必要的',
      settings: 'Cookie 设置'
    },
    cookieSettings: {
      title: 'Cookie 设置',
      essential: {
        title: '必要的 Cookie',
        description: '这些 Cookie 对网站的基本功能是必需的，无法禁用。'
      },
      analytics: {
        title: '分析 Cookie',
        description: '帮助我们了解访客如何使用网站，以改进网站体验。'
      },
      marketing: {
        title: '营销 Cookie',
        description: '用于跟踪访客在网站上的活动，以提供个性化的广告体验。'
      },
      save: '保存设置',
      cancel: '取消'
    },
    privacy: {
      title: '隐私政策',
      dataCollection: {
        title: '数据收集',
        description: '在SwiftSites，我们根据以下隐私政策收集和管理用户数据：',
        items: [
          '我们收集您直接提供给我们的信息，如姓名、电子邮件、联系方式等',
          '我们收集网站访问数据以监控流量并改进服务',
          '我们使用Cookie和类似技术来改善用户体验',
          '我们收集的数据用于提供和改进我们的服务'
        ]
      },
      dataUsage: {
        title: '数据使用',
        description: '我们如何使用收集的数据：',
        items: [
          '提供、维护和改进我们的服务',
          '处理您的请求和订单',
          '发送服务相关通知',
          '防止欺诈和滥用'
        ]
      },
      dataSharing: {
        title: '数据共享',
        description: '我们如何保护您的数据：',
        items: [
          '我们不会出售您的个人信息',
          '只在必要时与服务提供商共享数据',
          '遵守法律要求时可能披露信息',
          '采取措施保护数据安全'
        ]
      },
      security: {
        title: '安全措施',
        description: '我们采用行业标准的安全措施保护您的数据，包括加密传输、安全存储和访问控制。'
      },
      children: {
        title: '儿童隐私',
        description: '我们的服务不面向13岁以下儿童，我们不会故意收集儿童的个人信息。'
      },
      changes: {
        title: '政策更新',
        description: '我们可能会更新本隐私政策，更新时会在网站上发布通知。'
      },
      footer: '© 2024 SwiftSites. 保留所有权利。'
    }
  },
  en: {
    nav: {
      features: 'Features',
      portfolio: 'Portfolio',
      pricing: 'Pricing',
      contact: 'Contact',
      startProject: 'Start Project'
    },
    hero: {
      title: 'Smart Choice for Professional Websites',
      subtitle: 'From concept to launch, we provide one-stop website development solutions',
      cta: 'Get Started'
    },
    form: {
      title: 'Customize Your Website',
      websiteType: {
        title: 'Select Website Type',
        business: { label: 'Business Website', description: 'Showcase your business image and services' },
        ecommerce: { label: 'E-commerce', description: 'Sell products and services online' },
        portfolio: { label: 'Portfolio', description: 'Showcase personal or team projects' },
        blog: { label: 'Personal Blog', description: 'Share articles and content' },
        landing: { label: 'Marketing Landing Page', description: 'Promote products or services' },
        custom: { label: 'Custom Solution', description: 'Customized website development solution' }
      },
      content: {
        title: 'Content Requirements',
        language: 'Website Language',
        contentCreation: 'Content Creation',
        hasContent: 'I have prepared content',
        needsContent: 'Need assistance creating content'
      },
      basicInfo: {
        title: 'Basic Information',
        businessName: 'Business/Brand Name',
        businessNamePlaceholder: 'Enter your business or brand name',
        industry: 'Industry',
        industryPlaceholder: 'Enter your industry',
        targetAudience: 'Target Audience',
        targetAudiencePlaceholder: 'Describe your target customer group'
      },
      budget: {
        title: 'Budget and Time',
        range: 'Budget Range',
        timeline: 'Expected Completion Time',
        ranges: {
          basic: 'RM 3,000 - RM 5,000 (Basic Website)',
          standard: 'RM 5,000 - RM 10,000 (Standard Plan)',
          premium: 'RM 10,000 - RM 20,000 (Premium Customization)',
          business: 'RM 20,000 - RM 50,000 (Business Solution)',
          enterprise: 'RM 50,000 - RM 100,000 (Enterprise Customization)',
          custom: 'Above RM 100,000 (Comprehensive Customization)',
          flexible: 'Flexible Budget (Negotiable)'
        },
        timelines: {
          urgent: 'Complete ASAP (1-2 weeks)',
          normal: 'Normal Progress (3-4 weeks)',
          flexible: 'Flexible Time'
        }
      },
      contact: {
        title: 'Contact Information',
        name: 'Contact Person Name',
        namePlaceholder: 'Enter your name',
        phone: 'Phone Number',
        phonePlaceholder: 'Enter your phone number',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        preferredContact: 'Preferred Contact Method',
        contactMethods: {
          phone: 'Phone',
          email: 'Email',
          whatsapp: 'WhatsApp'
        }
      },
      other: {
        title: 'Other Requirements',
        placeholder: 'Please describe any other special requirements or notes'
      },
      submit: 'Submit Requirements'
    },
    features: {
      title: 'Our Advantages',
      subtitle: 'Professional website development services to drive digital transformation',
      items: [
        {
          icon: '⚡',
          title: 'Fast Delivery',
          description: 'Quick 7-14 day delivery with professional team execution',
          stats: '90%',
          statsText: 'On-time delivery'
        },
        {
          icon: '🎨',
          title: 'Creative Design',
          description: 'Professional design team creating unique brand visuals',
          stats: '100+',
          statsText: 'Success cases'
        },
        {
          icon: '📱',
          title: 'Full Adaptation',
          description: 'Perfect adaptation for all devices, seamless user experience',
          stats: '99%',
          statsText: 'Compatibility'
        },
        {
          icon: '🛠️',
          title: 'One-Stop Service',
          description: 'Full technical support from domain to maintenance',
          stats: '24/7',
          statsText: 'Technical support'
        }
      ]
    },
    footer: {
      description: 'Professional website development services to drive digital transformation',
      quickLinks: 'Quick Links',
      features: 'Features',
      portfolio: 'Portfolio',
      pricing: 'Pricing',
      contact: 'Contact',
      newsletter: 'Newsletter',
      newsletterDesc: 'Subscribe to our newsletter for latest updates and offers',
      emailPlaceholder: 'Enter your email',
      subscribe: 'Subscribe',
      address: 'Kuala Lumpur City Centre, Malaysia',
      rights: 'All rights reserved',
      privacy: 'Privacy Policy',
      terms: 'Terms of Use',
      cookies: 'Cookie Policy'
    },
    portfolio: {
      title: 'Success Cases',
      subtitle: 'Our Selected Projects Showcase',
      viewProject: 'View Project',
      categories: {
        ecommerce: 'E-commerce',
        business: 'Business Website',
        portfolio: 'Portfolio',
        blog: 'Blog',
        landing: 'Marketing Landing Page',
        custom: 'Custom Solution'
      },
      items: [
        {
          title: 'High-end Fashion E-commerce Platform',
          description: 'A modern e-commerce website for well-known brands, supporting multiple languages and cross-border payments'
        },
        {
          title: 'Corporate Brand Website',
          description: 'A professional corporate image showcase website, highlighting brand value and service advantages'
        },
        {
          title: 'Creative Design Studio',
          description: 'A unique showcase platform for designers, showcasing their creative ideas'
        },
        {
          title: 'Lifestyle Blog',
          description: 'A clean and simple blog website, sharing life inspirations and stories'
        },
        {
          title: 'Product Marketing Landing Page',
          description: 'A high-converting marketing page highlighting product features and value'
        },
        {
          title: 'Customized Solution',
          description: 'A special feature website customized according to customer needs'
        }
      ]
    },
    pricing: {
      title: 'Pricing',
      subtitle: 'Choose the most suitable plan for your professional website journey',
      recommendedBadge: 'Recommended Plan',
      monthly: 'per month',
      selectPlan: 'Select Plan',
      note: 'Need more customization? We offer fully customized solutions',
      customQuote: 'Get a Custom Quote',
      plans: {
        basic: {
          name: 'Basic Plan',
          description: 'Ideal for small businesses and personal sites',
          features: [
            'Responsive website design',
            '5 pages of content',
            'Basic SEO optimization',
            'Free domain name for 1 year',
            'Basic technical support',
            '7-day quick delivery'
          ]
        },
        standard: {
          name: 'Standard Plan',
          description: 'Ideal for growing businesses and e-commerce websites',
          features: [
            'Advanced responsive design',
            '10 pages of content',
            'Complete SEO optimization',
            'Free domain name and hosting',
            'Multi-language support',
            'Online payment integration',
            'Priority technical support',
            '14-day delivery'
          ]
        },
        premium: {
          name: 'Premium Plan',
          description: 'Ideal for large enterprises and high customization needs',
          features: [
            'Custom design',
            'Unlimited pages',
            'Advanced SEO optimization',
            'Full suite of domain and hosting services',
            'Multi-language and multi-currency',
            'Advanced customization',
            '24/7 technical support',
            'Dedicated project manager',
            '30-day delivery'
          ]
        }
      }
    },
    contact: {
      title: 'Contact Us',
      subtitle: 'We are here to help you. Get in touch with us anytime.',
      address: {
        title: 'Address',
        value: 'Kuala Lumpur City Centre, Malaysia'
      },
      phone: {
        title: 'Phone',
        value: '+60 12-345 6789'
      },
      email: {
        title: 'Email',
        value: 'contact@swiftsites.my'
      },
      hours: {
        title: 'Working Hours',
        value: 'Monday - Friday 9:00 - 18:00'
      },
      form: {
        name: 'Name',
        namePlaceholder: 'Enter your name',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        phone: 'Phone',
        phonePlaceholder: 'Enter your phone number',
        subject: 'Subject',
        subjectPlaceholder: 'Enter your subject',
        message: 'Message',
        messagePlaceholder: 'Enter your message',
        submit: 'Send Message',
        submitted: 'Sent'
      },
      social: {
        title: 'Follow Us'
      }
    },
    benefits: {
      title: 'Benefits of Having a Professional Website',
      subtitle: 'In the digital age, a professional website is key to business success',
      items: [
        {
          icon: '🌐',
          title: 'Enhance Brand Image',
          description: 'Professional website design effectively enhances brand value and market recognition',
          points: [
            'Establish professional and trustworthy brand image',
            'Showcase company culture and values',
            'Increase brand awareness and influence',
            'Build industry professional status'
          ]
        },
        {
          icon: '💼',
          title: 'Expand Business Opportunities',
          description: '24/7 online presence, never miss potential business opportunities',
          points: [
            'Break geographical limitations',
            'Increase customer touchpoints',
            'Provide 24/7 business consultation',
            'Reduce customer acquisition costs'
          ]
        },
        {
          icon: '📱',
          title: 'Optimize Customer Experience',
          description: 'Provide convenient information access and interaction methods',
          points: [
            'Quick response to customer needs',
            'Easy information access',
            'Optimized browsing experience',
            'Effective communication channels'
          ]
        },
        {
          icon: '📈',
          title: 'Enhance Competitive Advantage',
          description: 'Maintain leading position in digital competition',
          points: [
            'Showcase product advantages',
            'Provide online support',
            'Collect market feedback',
            'Quick market response'
          ]
        },
        {
          icon: '🎯',
          title: 'Precise Marketing',
          description: 'Achieve precise customer targeting through digital marketing',
          points: [
            'SEO optimization',
            'Social media integration',
            'Data analysis strategies',
            'Improve conversion rates'
          ]
        },
        {
          icon: '💡',
          title: 'Innovative Business Model',
          description: 'Develop online channels and innovate service models',
          points: [
            'Develop online services',
            'Build membership systems',
            'Optimize business processes',
            'Provide value-added services'
          ]
        }
      ]
    },
    terms: {
      title: 'Terms of Use',
      acceptance: {
        title: 'Acceptance of Terms',
        description: 'By using this website, you agree to these terms. If you do not agree to these terms, please do not use this website.'
      },
      services: {
        title: 'Services Description',
        description: 'We provide the following services:',
        items: [
          'Website design and development',
          'Website maintenance and support',
          'Domain and hosting services',
          'Website optimization services'
        ]
      },
      payment: {
        title: 'Payment Terms',
        description: 'Payment regulations:',
        items: [
          'All prices are in Malaysian Ringgit (RM)',
          '50% deposit required',
          'Balance payment upon project completion',
          'Multiple payment methods supported'
        ]
      },
      intellectual: {
        title: 'Intellectual Property',
        description: 'All website content intellectual property rights belong to SwiftSites and may not be used without authorization.'
      },
      termination: {
        title: 'Termination',
        description: 'We reserve the right to terminate services at any time, especially in cases of terms violation.'
      },
      liability: {
        title: 'Limitation of Liability',
        description: 'To the extent permitted by law, we are not liable for any losses that may arise from using this website.'
      },
      footer: '© 2024 SwiftSites. All rights reserved.'
    },
    cookies: {
      title: 'Cookie Policy',
      what: {
        title: 'What are Cookies',
        description: 'Cookies are small text files stored on your device to improve your website browsing experience.'
      },
      types: {
        title: 'Types of Cookies',
        items: [
          {
            name: 'Essential Cookies',
            description: 'Required for basic website functionality',
            duration: 'Session'
          },
          {
            name: 'Functional Cookies',
            description: 'Remember your preferences',
            duration: '1 year'
          },
          {
            name: 'Analytics Cookies',
            description: 'Help us improve the website',
            duration: '2 years'
          },
          {
            name: 'Marketing Cookies',
            description: 'Used for personalized advertising',
            duration: '90 days'
          }
        ]
      },
      control: {
        title: 'Cookie Control',
        description: 'You can control cookies through:',
        methods: [
          'Browser settings',
          'Privacy mode',
          'Cookie management tools',
          'Third-party plugins'
        ]
      },
      thirdParty: {
        title: 'Third-Party Cookies',
        description: 'Our website uses the following third-party services:',
        services: [
          'Google Analytics',
          'Facebook Pixel',
          'LinkedIn Insight',
          'HubSpot'
        ]
      },
      footer: '© 2024 SwiftSites. All rights reserved.'
    },
    cookieConsent: {
      message: 'We use cookies to improve your browsing experience. You can choose to accept all cookies, or just necessary cookies.',
      learnMore: 'Learn more',
      acceptAll: 'Accept all',
      acceptEssential: 'Only accept necessary',
      settings: 'Cookie settings'
    },
    cookieSettings: {
      title: 'Cookie Settings',
      essential: {
        title: 'Essential Cookies',
        description: 'These cookies are essential for the basic functionality of the website and cannot be disabled.'
      },
      analytics: {
        title: 'Analytics Cookies',
        description: 'Help us understand how visitors use the website to improve the user experience.'
      },
      marketing: {
        title: 'Marketing Cookies',
        description: 'Used to track visitors'
      },
      save: 'Save Settings',
      cancel: 'Cancel'
    },
    privacy: {
      title: 'Privacy Policy',
      dataCollection: {
        title: 'Data Collection',
        description: 'At SwiftSites, we collect and manage user data based on the following privacy policy:',
        items: [
          'We collect information you directly provide to us, such as name, email, contact information, etc.',
          'We collect website access data to monitor traffic and improve services',
          'We use cookies and similar technologies to improve user experience',
          'The data collected is used to provide and improve our services'
        ]
      },
      dataUsage: {
        title: 'Data Usage',
        description: 'How we use the collected data:',
        items: [
          'Providing, maintaining, and improving our services',
          'Processing your requests and orders',
          'Sending service-related notifications',
          'Preventing fraud and abuse'
        ]
      },
      dataSharing: {
        title: 'Data Sharing',
        description: 'How we protect your data:',
        items: [
          'We do not sell your personal information',
          'We share data with service providers only when necessary',
          'We may disclose information when required by law',
          'We take measures to protect data security'
        ]
      },
      security: {
        title: 'Security Measures',
        description: 'We use industry-standard security measures to protect your data, including encryption, secure storage, and access control.'
      },
      children: {
        title: 'Children Privacy',
        description: 'Our services are not directed to children under 13, and we do not intentionally collect personal information of children.'
      },
      changes: {
        title: 'Policy Updates',
        description: 'We may update this privacy policy at any time, and we will notify you on the website.'
      },
      footer: '© 2024 SwiftSites. All rights reserved.'
    }
  },
  ms: {
    nav: {
      features: 'Ciri-ciri',
      portfolio: 'Portfolio',
      pricing: 'Harga',
      contact: 'Hubungi',
      startProject: 'Mulakan Projek'
    },
    hero: {
      title: 'Pilihan Pintar untuk Laman Web Profesional',
      subtitle: 'Dari konsep hingga pelancaran, kami menyediakan penyelesaian pembangunan laman web sehenti',
      cta: 'Mulakan'
    },
    form: {
      title: 'Kustomisasi Laman Web Anda',
      websiteType: {
        title: 'Pilih Jenis Laman Web',
        business: { label: 'Laman Web Syarikat', description: 'Tunjukkan citra dan perkhidmatan syarikat' },
        ecommerce: { label: 'E-commerce', description: 'Jual produk dan perkhidmatan online' },
        portfolio: { label: 'Portfolio', description: 'Tunjukkan kerja individu atau tim' },
        blog: { label: 'Blog Peribadi', description: 'Kongsi artikel dan kandungan' },
        landing: { label: 'Halaman Penjualan', description: 'Promote produk atau perkhidmatan' },
        custom: { label: 'Penyelesaian Kustom', description: 'Penyelesaian pembangunan laman web khusus' }
      },
      content: {
        title: 'Keperluan Kandungan',
        language: 'Bahasa Laman Web',
        contentCreation: 'Penciptaan Kandungan',
        hasContent: 'Saya sudah menyiapkan kandungan',
        needsContent: 'Memerlukan bantuan mencipta kandungan'
      },
      basicInfo: {
        title: 'Informasi Dasar',
        businessName: 'Nama Syarikat/Brand',
        businessNamePlaceholder: 'Masukkan nama syarikat atau brand',
        industry: 'Industri',
        industryPlaceholder: 'Masukkan industri',
        targetAudience: 'Pengikut Tujuan',
        targetAudiencePlaceholder: 'Deskripsikan kumpulan pelanggan tujuan'
      },
      budget: {
        title: 'Anggaran dan Masa',
        range: 'Jangkaan Anggaran',
        timeline: 'Jangkaan Penghasilan',
        ranges: {
          basic: 'RM 3,000 - RM 5,000 (Website Dasar)',
          standard: 'RM 5,000 - RM 10,000 (Rancangan Standard)',
          premium: 'RM 10,000 - RM 20,000 (Kustomisasi Premium)',
          business: 'RM 20,000 - RM 50,000 (Rancangan Penyelesaian Syarikat)',
          enterprise: 'RM 50,000 - RM 100,000 (Kustomisasi Syarikat Besar)',
          custom: 'Di atas RM 100,000 (Rancangan Kustomisasi Lengkap)',
          flexible: 'Anggaran Fleksibel (Boleh Dikehendaki)'
        },
        timelines: {
          urgent: 'Selesai secepat mungkin (1-2 minggu)',
          normal: 'Kemajuan normal (3-4 minggu)',
          flexible: 'Masa fleksibel'
        }
      },
      contact: {
        title: 'Informasi Kontak',
        name: 'Nama Penghubung',
        namePlaceholder: 'Masukkan nama penghubung',
        phone: 'Nombor Telefon',
        phonePlaceholder: 'Masukkan nombor telefon',
        email: 'Email',
        emailPlaceholder: 'Masukkan email',
        preferredContact: 'Kaedah Kontak Terpilih',
        contactMethods: {
          phone: 'Telefon',
          email: 'Email',
          whatsapp: 'WhatsApp'
        }
      },
      other: {
        title: 'Persyaratan Lain',
        placeholder: 'Sila deskripsikan persyaratan lain atau catatan'
      },
      submit: 'Kirim Persyaratan'
    },
    features: {
      title: 'Kelebihan Kami',
      subtitle: 'Perkhidmatan pembangunan laman web profesional untuk memacu transformasi digital',
      items: [
        {
          icon: '⚡',
          title: 'Penghantaran Cepat',
          description: 'Penghantaran cepat 7-14 hari dengan pelaksanaan pasukan profesional',
          stats: '90%',
          statsText: 'Penghantaran tepat masa'
        },
        {
          icon: '🎨',
          title: 'Reka Bentuk Kreatif',
          description: 'Pasukan reka bentuk profesional mencipta visual jenama unik',
          stats: '100+',
          statsText: 'Kes kejayaan'
        },
        {
          icon: '📱',
          title: 'Penyesuaian Penuh',
          description: 'Penyesuaian sempurna untuk semua peranti, pengalaman pengguna lancar',
          stats: '99%',
          statsText: 'Keserasian'
        },
        {
          icon: '🛠️',
          title: 'Perkhidmatan Sehenti',
          description: 'Sokongan teknikal penuh dari domain hingga penyelenggaraan',
          stats: '24/7',
          statsText: 'Sokongan teknikal'
        }
      ]
    },
    footer: {
      description: 'Perkhidmatan pembangunan laman web profesional untuk memacu transformasi digital',
      quickLinks: 'Pautan Pantas',
      features: 'Ciri-ciri',
      portfolio: 'Portfolio',
      pricing: 'Harga',
      contact: 'Hubungi',
      newsletter: 'Surat Berita',
      newsletterDesc: 'Langgan surat berita kami untuk kemas kini dan tawaran terkini',
      emailPlaceholder: 'Masukkan emel anda',
      subscribe: 'Langgan',
      address: 'Pusat Bandaraya Kuala Lumpur, Malaysia',
      rights: 'Hak cipta terpelihara',
      privacy: 'Dasar Privasi',
      terms: 'Terma Penggunaan',
      cookies: 'Dasar Kuki'
    },
    portfolio: {
      title: 'Kes Sukses',
      subtitle: 'Projek Kami yang Dipilih',
      viewProject: 'Lihat Projek',
      categories: {
        ecommerce: 'E-commerce',
        business: 'Laman Web Syarikat',
        portfolio: 'Portfolio',
        blog: 'Blog',
        landing: 'Halaman Penjualan',
        custom: 'Penyelesaian Kustom'
      },
      items: [
        {
          title: 'Platform E-commerce Fashion Tinggi',
          description: 'Laman web e-commerce modern untuk brand terkenal, mendukung pelbagai bahasa dan pembayaran跨境'
        },
        {
          title: 'Laman Web Brand Syarikat',
          description: 'Laman web penunjuk citra profesional untuk syarikat, menekankan nilai dan kelebihan layanan'
        },
        {
          title: 'Studio Reka Bentuk Kreatif',
          description: 'Platform penunjukkan kerja individu atau tim, menunjukkan idea reka bentuk kreatif'
        },
        {
          title: 'Blog Gayakan Hidup',
          description: 'Laman blog bersih dan sederhana, berbagi inspirasi hidup'
        },
        {
          title: 'Halaman Penjualan Produk',
          description: 'Halaman penjualan pemarketing tinggi, menekankan ciri khas dan nilai produk'
        },
        {
          title: 'Penyelesaian Kustom',
          description: 'Laman web khas dengan ciri khas untuk memenuhi keperluan pelanggan'
        }
      ]
    },
    pricing: {
      title: 'Harga',
      subtitle: 'Pilih rencana yang paling sesuai untuk perjalanan website profesional Anda',
      recommendedBadge: 'Rencana yang Direkomendasikan',
      monthly: 'per bulan',
      selectPlan: 'Pilih Rencana',
      note: 'Butuh lebih banyak penyesuaian? Kami menawarkan solusi penyesuaian lengkap',
      customQuote: 'Dapatkan Penawaran Kustom',
      plans: {
        basic: {
          name: 'Rencana Dasar',
          description: 'Ideal untuk bisnis kecil dan situs pribadi',
          features: [
            'Desain website responsif',
            '5 halaman kandungan',
            'Optimasi SEO dasar',
            'Domain gratis selama 1 tahun',
            'Dukungan teknikal dasar',
            'Penghantaran cepat 7 hari'
          ]
        },
        standard: {
          name: 'Rencana Standar',
          description: 'Ideal untuk bisnis berkembang dan toko online',
          features: [
            'Desain responsif tinggi',
            '10 halaman kandungan',
            'Optimasi SEO lengkap',
            'Domain dan hosting gratis',
            'Dukungan multi-bahasa',
            'Integrasi pembayaran online',
            'Dukungan teknikal prioritas',
            'Penghantaran 14 hari'
          ]
        },
        premium: {
          name: 'Rencana Premium',
          description: 'Ideal untuk perusahaan besar dan kebutuhan penyesuaian tinggi',
          features: [
            'Penyesuaian desain',
            'Tak terbatas halaman',
            'Optimasi SEO tinggi',
            'Seluruh paket layanan domain dan hosting',
            'Multi-bahasa dan multi-mata uang',
            'Penyesuaian tingkat lanjut',
            'Dukungan teknikal 24/7',
            'Manajer proyek khusus',
            'Penghantaran 30 hari'
          ]
        }
      }
    },
    contact: {
      title: 'Hubungi Kami',
      subtitle: 'Kami siap membantu Anda. Hubungi kami kapan saja.',
      address: {
        title: 'Alamat',
        value: 'Pusat Bandaraya Kuala Lumpur, Malaysia'
      },
      phone: {
        title: 'Telefon',
        value: '+60 12-345 6789'
      },
      email: {
        title: 'Email',
        value: 'contact@swiftsites.my'
      },
      hours: {
        title: 'Waktu Kerja',
        value: 'Senin - Jumaat 9:00 - 18:00'
      },
      form: {
        name: 'Nama',
        namePlaceholder: 'Masukkan nama Anda',
        email: 'Email',
        emailPlaceholder: 'Masukkan email Anda',
        phone: 'Telefon',
        phonePlaceholder: 'Masukkan nombor telefon Anda',
        subject: 'Tema',
        subjectPlaceholder: 'Masukkan tema konsultasi',
        message: 'Pesan',
        messagePlaceholder: 'Masukkan isi pesan Anda',
        submit: 'Kirim Pesan',
        submitted: 'Terkirim'
      },
      social: {
        title: 'Ikuti Kami'
      }
    },
    benefits: {
      title: 'Kelebihan Memiliki Laman Web Profesional',
      subtitle: 'Dalam era digital, laman web profesional adalah kunci kejayaan perniagaan',
      items: [
        {
          icon: '🌐',
          title: 'Meningkatkan Imej Jenama',
          description: 'Reka bentuk laman web profesional meningkatkan nilai jenama dan pengiktirafan pasaran',
          points: [
            'Membina imej jenama profesional',
            'Memaparkan budaya syarikat',
            'Meningkatkan kesedaran jenama',
            'Membina status profesional industri'
          ]
        },
        {
          icon: '💼',
          title: 'Mengembangkan Peluang Perniagaan',
          description: 'Kehadiran dalam talian 24/7, tidak terlepas peluang perniagaan',
          points: [
            'Mengatasi batasan geografi',
            'Meningkatkan titik sentuh pelanggan',
            'Menyediakan konsultasi 24/7',
            'Mengurangkan kos pemerolehan pelanggan'
          ]
        },
        {
          icon: '📱',
          title: 'Mengoptimumkan Pengalaman Pelanggan',
          description: 'Menyediakan akses maklumat dan kaedah interaksi yang mudah',
          points: [
            'Tindak balas pantas kepada pelanggan',
            'Akses maklumat mudah',
            'Pengalaman pelayaran optimum',
            'Saluran komunikasi berkesan'
          ]
        },
        {
          icon: '📈',
          title: 'Meningkatkan Kelebihan Bersaing',
          description: 'Mengekalkan kedudukan terkemuka dalam persaingan digital',
          points: [
            'Memaparkan kelebihan produk',
            'Menyediakan sokongan dalam talian',
            'Mengumpul maklum balas pasaran',
            'Tindak balas pasaran pantas'
          ]
        },
        {
          icon: '🎯',
          title: 'Pemasaran Tepat',
          description: 'Mencapai sasaran pelanggan tepat melalui pemasaran digital',
          points: [
            'Pengoptimuman SEO',
            'Integrasi media sosial',
            'Strategi analisis data',
            'Meningkatkan kadar penukaran'
          ]
        },
        {
          icon: '💡',
          title: 'Model Perniagaan Inovatif',
          description: 'Membangunkan saluran dalam talian dan model perkhidmatan inovatif',
          points: [
            'Membangunkan perkhidmatan dalam talian',
            'Membina sistem keahlian',
            'Mengoptimumkan proses perniagaan',
            'Menyediakan perkhidmatan nilai tambah'
          ]
        }
      ]
    },
    terms: {
      title: 'Terma Penggunaan',
      acceptance: {
        title: 'Penerimaan Terma',
        description: 'Dengan menggunakan laman web ini, anda bersetuju dengan terma-terma ini. Jika anda tidak bersetuju dengan terma-terma ini, sila jangan gunakan laman web ini.'
      },
      services: {
        title: 'Penerangan Perkhidmatan',
        description: 'Kami menyediakan perkhidmatan berikut:',
        items: [
          'Reka bentuk dan pembangunan laman web',
          'Penyelenggaraan dan sokongan laman web',
          'Perkhidmatan domain dan hosting',
          'Perkhidmatan pengoptimuman laman web'
        ]
      },
      payment: {
        title: 'Terma Pembayaran',
        description: 'Peraturan pembayaran:',
        items: [
          'Semua harga dalam Ringgit Malaysia (RM)',
          'Deposit 50% diperlukan',
          'Baki pembayaran selepas projek siap',
          'Pelbagai kaedah pembayaran disokong'
        ]
      },
      intellectual: {
        title: 'Harta Intelek',
        description: 'Semua hak harta intelek kandungan laman web adalah milik SwiftSites dan tidak boleh digunakan tanpa kebenaran.'
      },
      termination: {
        title: 'Penamatan',
        description: 'Kami berhak untuk menamatkan perkhidmatan pada bila-bila masa, terutamanya dalam kes pelanggaran terma.'
      },
      liability: {
        title: 'Had Liabiliti',
        description: 'Setakat yang dibenarkan oleh undang-undang, kami tidak bertanggungjawab atas sebarang kerugian yang mungkin timbul daripada penggunaan laman web ini.'
      },
      footer: '© 2024 SwiftSites. Hak cipta terpelihara.'
    },
    cookies: {
      title: 'Dasar Kuki',
      what: {
        title: 'Apa itu Kuki',
        description: 'Kuki adalah fail teks kecil yang disimpan pada peranti anda untuk meningkatkan pengalaman pelayaran laman web anda.'
      },
      types: {
        title: 'Jenis Kuki',
        items: [
          {
            name: 'Kuki Penting',
            description: 'Diperlukan untuk fungsi asas laman web',
            duration: 'Sesi'
          },
          {
            name: 'Kuki Fungsi',
            description: 'Mengingati keutamaan anda',
            duration: '1 tahun'
          },
          {
            name: 'Kuki Analitik',
            description: 'Membantu kami meningkatkan laman web',
            duration: '2 tahun'
          },
          {
            name: 'Kuki Pemasaran',
            description: 'Digunakan untuk pengiklanan peribadi',
            duration: '90 hari'
          }
        ]
      },
      control: {
        title: 'Kawalan Kuki',
        description: 'Anda boleh mengawal kuki melalui:',
        methods: [
          'Tetapan pelayar',
          'Mod privasi',
          'Alat pengurusan kuki',
          'Plugin pihak ketiga'
        ]
      },
      thirdParty: {
        title: 'Kuki Pihak Ketiga',
        description: 'Laman web kami menggunakan perkhidmatan pihak ketiga berikut:',
        services: [
          'Google Analytics',
          'Facebook Pixel',
          'LinkedIn Insight',
          'HubSpot'
        ]
      },
      footer: '© 2024 SwiftSites. Hak cipta terpelihara.'
    },
    cookieConsent: {
      message: 'Kuki adalah fail teks kecil yang disimpan pada peranti anda untuk meningkatkan pengalaman pelayaran laman web anda.',
      learnMore: 'Ketahui lebih lanjut',
      acceptAll: 'Terima semua',
      acceptEssential: 'Hanya terima yang diperlukan',
      settings: 'Tetapan Kuki'
    },
    cookieSettings: {
      title: 'Cookie Settings',
      essential: {
        title: 'Essential Cookies',
        description: 'These cookies are essential for the basic functionality of the website and cannot be disabled.'
      },
      analytics: {
        title: 'Analytics Cookies',
        description: 'Help us understand how visitors use the website to improve the user experience.'
      },
      marketing: {
        title: 'Marketing Cookies',
        description: 'Used to track visitors'
      },
      save: 'Save Settings',
      cancel: 'Cancel'
    },
    privacy: {
      title: 'Privacy Policy',
      dataCollection: {
        title: 'Data Collection',
        description: 'At SwiftSites, we collect and manage user data based on the following privacy policy:',
        items: [
          'We collect information you directly provide to us, such as name, email, contact information, etc.',
          'We collect website access data to monitor traffic and improve services',
          'We use cookies and similar technologies to improve user experience',
          'The data collected is used to provide and improve our services'
        ]
      },
      dataUsage: {
        title: 'Data Usage',
        description: 'How we use the collected data:',
        items: [
          'Providing, maintaining, and improving our services',
          'Processing your requests and orders',
          'Sending service-related notifications',
          'Preventing fraud and abuse'
        ]
      },
      dataSharing: {
        title: 'Data Sharing',
        description: 'How we protect your data:',
        items: [
          'We do not sell your personal information',
          'We share data with service providers only when necessary',
          'We may disclose information when required by law',
          'We take measures to protect data security'
        ]
      },
      security: {
        title: 'Security Measures',
        description: 'We use industry-standard security measures to protect your data, including encryption, secure storage, and access control.'
      },
      children: {
        title: 'Children Privacy',
        description: 'Our services are not directed to children under 13, and we do not intentionally collect personal information of children.'
      },
      changes: {
        title: 'Policy Updates',
        description: 'We may update this privacy policy at any time, and we will notify you on the website.'
      },
      footer: '© 2024 SwiftSites. All rights reserved.'
    }
  }
}; 