import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './CookieConsent.css';
import { CookieManager } from '../utils/cookieManager';

function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);
  const { language } = useContext(LanguageContext);
  const t = translations[language].cookieConsent;

  useEffect(() => {
    // 检查是否已经同意 Cookie
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setShowConsent(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setShowConsent(false);
    CookieManager.initAllCookies();
    CookieManager.initMarketingCookies();
  };

  const handleAcceptEssential = () => {
    localStorage.setItem('cookieConsent', 'essential');
    setShowConsent(false);
    CookieManager.initEssentialCookies();
  };

  if (!showConsent) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-content">
        <p>{t.message}</p>
        <Link to="/cookies" className="cookie-link">{t.learnMore}</Link>
      </div>
      <div className="cookie-buttons">
        <button onClick={handleAcceptEssential} className="cookie-button essential">
          {t.acceptEssential}
        </button>
        <button onClick={handleAcceptAll} className="cookie-button accept-all">
          {t.acceptAll}
        </button>
      </div>
    </div>
  );
}

export default CookieConsent; 