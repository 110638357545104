/* global gtag */
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Contact.css';

function Contact() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].contact;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://formspree.io/f/myzypbdn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          _replyto: 'swiftsitesmy@gmail.com',
          _subject: `SwiftSites 联系表单 - ${formData.subject}`,
          _template: 'template_cjw3p6s',
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          subject: formData.subject,
          message: formData.message
        })
      });

      if (response.ok) {
        setSubmitStatus('success');
        // 重置表单
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('发送失败:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setSubmitStatus(null);
      }, 3000);
    }
  };

  const handleContactClick = (type) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'contact_click', {
        'event_category': 'engagement',
        'event_label': type // phone, email, whatsapp 等
      });
    }
  };

  return (
    <section className="contact-section">
      <div className="contact-container">
        <h2 className="contact-title">{t.title}</h2>
        <p className="contact-subtitle">{t.subtitle}</p>

        <div className="contact-content">
          <div className="contact-info">
            <div className="info-card">
              <div className="info-icon">📍</div>
              <h3>{t.address.title}</h3>
              <p>{t.address.value}</p>
            </div>
            <div className="info-card">
              <div className="info-icon">📞</div>
              <h3>{t.phone.title}</h3>
              <p>{t.phone.value}</p>
            </div>
            <div className="info-card">
              <div className="info-icon">✉️</div>
              <h3>{t.email.title}</h3>
              <p>{t.email.value}</p>
            </div>
            <div className="info-card">
              <div className="info-icon">⏰</div>
              <h3>{t.hours.title}</h3>
              <p>{t.hours.value}</p>
            </div>
          </div>

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>{t.form.name}</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  placeholder={t.form.namePlaceholder}
                  required
                />
              </div>
              <div className="form-group">
                <label>{t.form.email}</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  placeholder={t.form.emailPlaceholder}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>{t.form.phone}</label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({...formData, phone: e.target.value})}
                  placeholder={t.form.phonePlaceholder}
                />
              </div>
              <div className="form-group">
                <label>{t.form.subject}</label>
                <input
                  type="text"
                  value={formData.subject}
                  onChange={(e) => setFormData({...formData, subject: e.target.value})}
                  placeholder={t.form.subjectPlaceholder}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label>{t.form.message}</label>
              <textarea
                value={formData.message}
                onChange={(e) => setFormData({...formData, message: e.target.value})}
                placeholder={t.form.messagePlaceholder}
                rows="6"
                required
              />
            </div>

            {submitStatus === 'success' && (
              <div className="submit-status success">
                {t.form.submitted}
              </div>
            )}
            {submitStatus === 'error' && (
              <div className="submit-status error">
                提交失败，请稍后重试或直接联系我们。
              </div>
            )}

            <button type="submit" className="submit-button">
              {isSubmitting ? t.form.submitting : t.form.submit}
            </button>
          </form>
        </div>

        <div className="social-links">
          <h3>{t.social.title}</h3>
          <div className="social-icons">
            <a href="https://facebook.com/swiftsites" target="_blank" rel="noopener noreferrer" onClick={() => handleContactClick('facebook')}>
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://instagram.com/swiftsites" target="_blank" rel="noopener noreferrer" onClick={() => handleContactClick('instagram')}>
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://wa.me/60123456789" target="_blank" rel="noopener noreferrer" onClick={() => handleContactClick('whatsapp')}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact; 