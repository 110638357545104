import React, { useState, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import { CookieManager } from '../utils/cookieManager';
import './CookieSettings.css';

function CookieSettings({ isOpen, onClose }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language].cookieSettings;
  const [settings, setSettings] = useState({
    essential: true, // 必要的 Cookie 始终启用
    analytics: CookieManager.getCookie('analytics_cookies') === 'true',
    marketing: CookieManager.getCookie('marketing_cookies') === 'true'
  });

  const handleSave = () => {
    if (settings.analytics) {
      CookieManager.setCookie('analytics_cookies', 'true');
    } else {
      CookieManager.deleteCookie('analytics_cookies');
    }

    if (settings.marketing) {
      CookieManager.setCookie('marketing_cookies', 'true');
    } else {
      CookieManager.deleteCookie('marketing_cookies');
    }

    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="cookie-settings-overlay">
      <div className="cookie-settings">
        <h2>{t.title}</h2>
        <div className="cookie-options">
          <div className="cookie-option">
            <label>
              <input
                type="checkbox"
                checked={settings.essential}
                disabled
              />
              {t.essential.title}
            </label>
            <p>{t.essential.description}</p>
          </div>
          <div className="cookie-option">
            <label>
              <input
                type="checkbox"
                checked={settings.analytics}
                onChange={(e) => setSettings({...settings, analytics: e.target.checked})}
              />
              {t.analytics.title}
            </label>
            <p>{t.analytics.description}</p>
          </div>
          <div className="cookie-option">
            <label>
              <input
                type="checkbox"
                checked={settings.marketing}
                onChange={(e) => setSettings({...settings, marketing: e.target.checked})}
              />
              {t.marketing.title}
            </label>
            <p>{t.marketing.description}</p>
          </div>
        </div>
        <div className="cookie-settings-buttons">
          <button onClick={onClose} className="cancel-button">{t.cancel}</button>
          <button onClick={handleSave} className="save-button">{t.save}</button>
        </div>
      </div>
    </div>
  );
}

export default CookieSettings; 