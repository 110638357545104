import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Terms.css';

function Terms() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].terms;

  return (
    <div className="terms-page">
      <div className="terms-container">
        <h1 className="terms-title">{t.title}</h1>
        
        <section className="terms-section">
          <h2>{t.acceptance.title}</h2>
          <p>{t.acceptance.description}</p>
        </section>

        <section className="terms-section">
          <h2>{t.services.title}</h2>
          <p>{t.services.description}</p>
          <ul>
            {t.services.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>

        <section className="terms-section">
          <h2>{t.payment.title}</h2>
          <p>{t.payment.description}</p>
          <ul>
            {t.payment.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>

        <section className="terms-section">
          <h2>{t.intellectual.title}</h2>
          <p>{t.intellectual.description}</p>
        </section>

        <section className="terms-section">
          <h2>{t.termination.title}</h2>
          <p>{t.termination.description}</p>
        </section>

        <section className="terms-section">
          <h2>{t.liability.title}</h2>
          <p>{t.liability.description}</p>
        </section>

        <footer className="terms-footer">
          <p>{t.footer}</p>
        </footer>
      </div>
    </div>
  );
}

export default Terms; 