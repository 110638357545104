import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Footer.css';
import CookieSettings from './CookieSettings';

function Footer() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].footer;
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-logo">SwiftSites.my</h3>
          <p className="footer-description">{t.description}</p>
          <div className="social-links">
            <a href="https://facebook.com/swiftsites" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://instagram.com/swiftsites" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://wa.me/60123456789" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>

        <div className="footer-section">
          <h4>{t.quickLinks}</h4>
          <ul>
            <li><Link to="/features">{t.features}</Link></li>
            <li><Link to="/portfolio">{t.portfolio}</Link></li>
            <li><Link to="/pricing">{t.pricing}</Link></li>
            <li><Link to="/contact">{t.contact}</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>{t.contact}</h4>
          <ul className="contact-info">
            <li>
              <i className="fas fa-phone"></i>
              <span>+60 12-345 6789</span>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <span>contact@swiftsites.my</span>
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <span>{t.address}</span>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>{t.newsletter}</h4>
          <p>{t.newsletterDesc}</p>
          <form className="newsletter-form" onSubmit={(e) => e.preventDefault()}>
            <input 
              type="email" 
              placeholder={t.emailPlaceholder}
              aria-label="Email subscription"
            />
            <button type="submit">{t.subscribe}</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>&copy; 2024 SwiftSites.my. {t.rights}</p>
          <div className="footer-links">
            <Link to="/privacy">{t.privacy}</Link>
            <Link to="/terms">{t.terms}</Link>
            <Link to="/cookies">{t.cookies}</Link>
            <button 
              className="cookie-settings-button"
              onClick={() => setShowCookieSettings(true)}
            >
              {t.cookieSettings}
            </button>
          </div>
        </div>
      </div>
      <CookieSettings 
        isOpen={showCookieSettings} 
        onClose={() => setShowCookieSettings(false)} 
      />
    </footer>
  );
}

export default Footer; 