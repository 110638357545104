/* global gtag */
import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Pricing.css';

function Pricing() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].pricing;

  const plans = [
    {
      id: 'basic',
      icon: '🚀',
      price: 'RM 3,000',
      period: t.monthly,
      features: t.plans.basic.features,
      recommended: false
    },
    {
      id: 'standard',
      icon: '⭐',
      price: 'RM 5,000',
      period: t.monthly,
      features: t.plans.standard.features,
      recommended: true
    },
    {
      id: 'premium',
      icon: '👑',
      price: 'RM 10,000',
      period: t.monthly,
      features: t.plans.premium.features,
      recommended: false
    }
  ];

  const handlePlanClick = (plan) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'select_plan', {
        'event_category': 'conversion',
        'event_label': plan.id,
        'plan_price': plan.price
      });
    }
  };

  return (
    <section id="pricing" className="pricing-section">
      <div className="pricing-container">
        <h2 className="pricing-title">{t.title}</h2>
        <p className="pricing-subtitle">{t.subtitle}</p>
        
        <div className="pricing-grid">
          {plans.map(plan => (
            <div 
              key={plan.id} 
              className={`pricing-card ${plan.recommended ? 'recommended' : ''}`}
              onClick={() => handlePlanClick(plan)}
            >
              {plan.recommended && (
                <div className="recommended-badge">{t.recommendedBadge}</div>
              )}
              <div className="pricing-icon">{plan.icon}</div>
              <h3 className="plan-name">{t.plans[plan.id].name}</h3>
              <div className="plan-price">
                <span className="price">{plan.price}</span>
                <span className="period">/ {plan.period}</span>
              </div>
              <p className="plan-description">{t.plans[plan.id].description}</p>
              <ul className="plan-features">
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <span className="feature-icon">✓</span>
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="plan-cta">{t.selectPlan}</button>
            </div>
          ))}
        </div>

        <div className="pricing-note">
          <p>{t.note}</p>
          <button className="custom-quote-btn">{t.customQuote}</button>
        </div>
      </div>
    </section>
  );
}

export default Pricing; 