import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Cookies.css';

function Cookies() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].cookies;

  return (
    <div className="cookies-page">
      <div className="cookies-container">
        <h1 className="cookies-title">{t.title}</h1>
        
        <section className="cookies-section">
          <h2>{t.what.title}</h2>
          <p>{t.what.description}</p>
        </section>

        <section className="cookies-section">
          <h2>{t.types.title}</h2>
          <div className="cookies-types">
            {t.types.items.map((type, index) => (
              <div key={index} className="cookie-type">
                <h3>{type.name}</h3>
                <p>{type.description}</p>
                <p className="cookie-duration">{type.duration}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="cookies-section">
          <h2>{t.control.title}</h2>
          <p>{t.control.description}</p>
          <ul>
            {t.control.methods.map((method, index) => (
              <li key={index}>{method}</li>
            ))}
          </ul>
        </section>

        <section className="cookies-section">
          <h2>{t.thirdParty.title}</h2>
          <p>{t.thirdParty.description}</p>
          <ul>
            {t.thirdParty.services.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </section>

        <footer className="cookies-footer">
          <p>{t.footer}</p>
        </footer>
      </div>
    </div>
  );
}

export default Cookies; 