import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Features.css';

function Features() {
  const { language } = useContext(LanguageContext);
  const t = translations[language]?.features;

  return (
    <section id="features" className="features-section">
      <div className="features-container">
        <h2 className="features-title">{t?.title}</h2>
        <p className="features-subtitle">{t?.subtitle}</p>
        
        <div className="advantages-grid">
          {t?.items?.map((feature, index) => (
            <div key={index} className="advantage-item">
              <div className="advantage-content">
                <h3 className="advantage-title">
                  <span className="advantage-icon">{feature.icon}</span>
                  {feature.title}
                </h3>
                <p className="advantage-description">{feature.description}</p>
                <div className="advantage-stats">
                  <div className="stats-highlight">
                    <span className="stats-number">{feature.stats}</span>
                    <span className="stats-text">{feature.statsText}</span>
                  </div>
                </div>
              </div>
              <div className="advantage-border"></div>
            </div>
          ))}
        </div>

        <div className="features-summary">
          <div className="summary-text">
            <h3>为什么选择我们？</h3>
            <p>专业的技术团队，丰富的行业经验，快速响应的服务支持</p>
            <ul className="summary-points">
              <li>✓ 专注网站建设10年+</li>
              <li>✓ 服务超过1000+客户</li>
              <li>✓ 100%项目按时交付</li>
              <li>✓ 7*24小时技术支持</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features; 