import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import './LanguageModal.css';

function LanguageModal() {
  const { setLanguage } = useContext(LanguageContext);
  const [showModal, setShowModal] = React.useState(true);

  useEffect(() => {
    const hasSelectedLanguage = localStorage.getItem('hasSelectedLanguage');
    if (hasSelectedLanguage) {
      setShowModal(false);
    }
  }, []);

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setShowModal(false);
    localStorage.setItem('hasSelectedLanguage', 'true');
  };

  if (!showModal) return null;

  return (
    <div className="language-modal-overlay">
      <div className="language-modal">
        <h2>请选择您的语言 / Please select your language / Sila pilih bahasa anda</h2>
        <div className="language-buttons">
          <button onClick={() => handleLanguageSelect('zh')}>
            中文
          </button>
          <button onClick={() => handleLanguageSelect('en')}>
            English
          </button>
          <button onClick={() => handleLanguageSelect('ms')}>
            Bahasa Melayu
          </button>
        </div>
      </div>
    </div>
  );
}

export default LanguageModal; 