/* global gtag */
import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Portfolio.css';

function Portfolio() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].portfolio;

  const portfolioItems = [
    {
      id: 1,
      image: '/images/portfolio/ecommerce.jpg',
      category: 'ecommerce',
      title: t.items[0].title,
      description: t.items[0].description,
      link: 'https://example.com/ecommerce'
    },
    {
      id: 2,
      image: '/images/portfolio/corporate.jpg',
      category: 'business',
      title: t.items[1].title,
      description: t.items[1].description,
      link: 'https://example.com/corporate'
    },
    {
      id: 3,
      image: '/images/portfolio/portfolio.jpg',
      category: 'portfolio',
      title: t.items[2].title,
      description: t.items[2].description,
      link: 'https://example.com/portfolio'
    },
    {
      id: 4,
      image: '/images/portfolio/blog.jpg',
      category: 'blog',
      title: t.items[3].title,
      description: t.items[3].description,
      link: 'https://example.com/blog'
    },
    {
      id: 5,
      image: '/images/portfolio/landing.jpg',
      category: 'landing',
      title: t.items[4].title,
      description: t.items[4].description,
      link: 'https://example.com/landing'
    },
    {
      id: 6,
      image: '/images/portfolio/custom.jpg',
      category: 'custom',
      title: t.items[5].title,
      description: t.items[5].description,
      link: 'https://example.com/custom'
    }
  ];

  const handleProjectClick = (project) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'view_portfolio', {
        'event_category': 'engagement',
        'event_label': project.title,
        'project_category': project.category
      });
    }
  };

  return (
    <section id="portfolio" className="portfolio-section">
      <div className="portfolio-container">
        <h2 className="portfolio-title">{t.title}</h2>
        <p className="portfolio-subtitle">{t.subtitle}</p>
        
        <div className="portfolio-grid">
          {portfolioItems.map(item => (
            <div key={item.id} className="portfolio-item">
              <div className="portfolio-image">
                <img src={item.image} alt={item.title} />
                <div className="portfolio-overlay">
                  <div className="portfolio-category">{t.categories[item.category]}</div>
                  <h3 className="portfolio-item-title">{item.title}</h3>
                  <p className="portfolio-description">{item.description}</p>
                  <a 
                    href={item.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="portfolio-link"
                    onClick={() => handleProjectClick(item)}
                  >
                    {t.viewProject}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Portfolio; 