import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import LanguageSwitch from './LanguageSwitch';

function Navbar() {
  const { language, setLanguage } = useContext(LanguageContext);
  const t = translations[language].nav;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(() => {
    const savedState = sessionStorage.getItem('musicPlaying');
    return savedState === null ? true : JSON.parse(savedState);
  });

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const bgm = document.getElementById('bgm');
    if (bgm) {
      bgm.volume = 0.5;
      bgm.loop = true;
      
      sessionStorage.setItem('musicPlaying', JSON.stringify(isPlaying));

      const handleVisibilityChange = () => {
        if (document.hidden) {
          bgm.pause();
        } else if (isPlaying) {
          bgm.play().catch(() => {});
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      if (isPlaying) {
        const playPromise = bgm.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            const handleUserInteraction = () => {
              if (isPlaying) {
                bgm.play().catch(() => {});
              }
              document.removeEventListener('click', handleUserInteraction);
            };
            document.addEventListener('click', handleUserInteraction);
          });
        }
      } else {
        bgm.pause();
      }

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isPlaying]);

  const toggleMusic = async () => {
    const bgm = document.getElementById('bgm');
    try {
      if (bgm.paused) {
        const playPromise = bgm.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
              sessionStorage.setItem('musicPlaying', 'true');
            })
            .catch(error => {
              console.error("播放失败:", error);
              setIsPlaying(false);
              sessionStorage.setItem('musicPlaying', 'false');
            });
        }
      } else {
        bgm.pause();
        setIsPlaying(false);
        sessionStorage.setItem('musicPlaying', 'false');
      }
    } catch (error) {
      console.error("音频控制错误:", error);
    }
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="nav-content">
        <div className="nav-logo">
          <Link to="/">
            <span className="logo-text">SwiftSites.my</span>
          </Link>
        </div>
        
        <div className="nav-toggle" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <Link to="/features">{t.features}</Link>
          <Link to="/portfolio">{t.portfolio}</Link>
          <Link to="/pricing">{t.pricing}</Link>
          <Link to="/contact">{t.contact}</Link>
          <button className="nav-cta">{t.startProject}</button>
          <button 
            className="music-toggle"
            onClick={toggleMusic}
            aria-label="Toggle music"
          >
            {isPlaying ? '🔊' : '🔇'}
          </button>
          
          <div className="mobile-language-options">
            <button 
              onClick={() => handleLanguageChange('zh')} 
              className={language === 'zh' ? 'active' : ''}
            >
              中文
            </button>
            <button 
              onClick={() => handleLanguageChange('en')} 
              className={language === 'en' ? 'active' : ''}
            >
              English
            </button>
            <button 
              onClick={() => handleLanguageChange('ms')} 
              className={language === 'ms' ? 'active' : ''}
            >
              Bahasa Melayu
            </button>
          </div>
        </div>

        <div className="desktop-language-switch">
          <LanguageSwitch />
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 