/* global gtag */
import React, { useState, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './WebsiteForm.css';
import emailjs from '@emailjs/browser';

// 添加网站类型定义
const websiteTypes = [
  {
    id: 'business',
    icon: '🏢'
  },
  {
    id: 'ecommerce',
    icon: '🛍️'
  },
  {
    id: 'portfolio',
    icon: '🎨'
  },
  {
    id: 'blog',
    icon: '📝'
  },
  {
    id: 'landing',
    icon: '🎯'
  },
  {
    id: 'custom',
    icon: '⚙️'
  }
];

const websiteTypeMap = {
  'business': '企业官网',
  'ecommerce': '电子商务网站',
  'portfolio': '作品集展示',
  'blog': '博客网站',
  'landing': '营销落地页',
  'custom': '自定义网站'
};

const budgetMap = {
  'basic': 'RM 5,000 - RM 10,000',
  'standard': 'RM 10,000 - RM 20,000',
  'premium': 'RM 20,000 以上',
  'custom': '需要详细讨论'
};

const timelineMap = {
  'urgent': '1-2周',
  'normal': '2-4周',
  'relaxed': '1-2个月',
  'flexible': '时间灵活'
};

function WebsiteForm() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].form;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const [formData, setFormData] = useState({
    websiteType: '',
    businessName: '',
    industry: '',
    targetAudience: '',
    contentDetails: {
      languages: [],
      needsContentCreation: false
    },
    budget: '',
    timeline: '',
    contact: {
      name: '',
      email: '',
      phone: '',
      preferredContact: ''
    },
    additionalRequirements: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await emailjs.send(
        'service_1nyf7as',
        'template_cjw3p6s',
        {
          name: formData.contact.name,
          phone: formData.contact.phone,
          email: formData.contact.email,
          businessName: formData.businessName,
          industry: formData.industry,
          targetAudience: formData.targetAudience,
          websiteType: websiteTypeMap[formData.websiteType] || formData.websiteType,
          budget: budgetMap[formData.budget] || formData.budget,
          timeline: timelineMap[formData.timeline] || formData.timeline,
          languages: formData.contentDetails.languages.join('、'),
          needsContent: formData.contentDetails.needsContentCreation ? '需要' : '不需要',
          additionalRequirements: formData.additionalRequirements
        },
        '8P60-UYu6gmvK0d1l'
      );

      if (response.status === 200) {
        setSubmitStatus('success');
        // 重置表单
        setFormData({
          websiteType: '',
          businessName: '',
          industry: '',
          targetAudience: '',
          contentDetails: {
            languages: [],
            needsContentCreation: false
          },
          budget: '',
          timeline: '',
          contact: {
            name: '',
            email: '',
            phone: '',
            preferredContact: ''
          },
          additionalRequirements: ''
        });
      } else {
        throw new Error('提交失败');
      }
    } catch (error) {
      console.error('发送失败:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setSubmitStatus(null), 3000);
    }
  };

  return (
    <section className="form-section">
      <div className="form-container">
        <h2>{t.title}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-layout">
            {/* 左侧选项区域 */}
            <div className="form-left">
              <div className="form-group">
                <h3>{t.websiteType.title}</h3>
                <div className="website-types">
                  {websiteTypes.map(type => (
                    <div 
                      key={type.id}
                      className={`type-card ${formData.websiteType === type.id ? 'selected' : ''}`}
                      onClick={() => setFormData({...formData, websiteType: type.id})}
                    >
                      <span className="type-icon">{type.icon}</span>
                      <span className="type-label">{t.websiteType[type.id].label}</span>
                      <p className="type-description">{t.websiteType[type.id].description}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-group">
                <h3>{t.content.title}</h3>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.content.language}</label>
                    <select
                      multiple
                      value={formData.contentDetails.languages}
                      onChange={(e) => setFormData({
                        ...formData,
                        contentDetails: {
                          ...formData.contentDetails,
                          languages: Array.from(e.target.selectedOptions, option => option.value)
                        }
                      })}
                    >
                      <option value="zh">中文</option>
                      <option value="en">English</option>
                      <option value="ms">Bahasa Melayu</option>
                    </select>
                  </div>
                  <div className="form-col">
                    <label>{t.content.contentCreation}</label>
                    <select
                      value={formData.contentDetails.needsContentCreation}
                      onChange={(e) => setFormData({
                        ...formData,
                        contentDetails: {
                          ...formData.contentDetails,
                          needsContentCreation: e.target.value === 'true'
                        }
                      })}
                    >
                      <option value="false">{t.content.hasContent}</option>
                      <option value="true">{t.content.needsContent}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* 右侧表单区域 */}
            <div className="form-right">
              <div className="form-group">
                <h3>{t.basicInfo.title}</h3>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.basicInfo.businessName}</label>
                    <input 
                      type="text"
                      value={formData.businessName}
                      onChange={(e) => setFormData({...formData, businessName: e.target.value})}
                      placeholder={t.basicInfo.businessNamePlaceholder}
                    />
                  </div>
                  <div className="form-col">
                    <label>{t.basicInfo.industry}</label>
                    <input 
                      type="text"
                      value={formData.industry}
                      onChange={(e) => setFormData({...formData, industry: e.target.value})}
                      placeholder={t.basicInfo.industryPlaceholder}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.basicInfo.targetAudience}</label>
                    <input 
                      type="text"
                      value={formData.targetAudience}
                      onChange={(e) => setFormData({...formData, targetAudience: e.target.value})}
                      placeholder={t.basicInfo.targetAudiencePlaceholder}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h3>{t.budget.title}</h3>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.budget.range}</label>
                    <select 
                      value={formData.budget}
                      onChange={(e) => setFormData({...formData, budget: e.target.value})}
                    >
                      <option value="">{t.budget.range}</option>
                      {Object.entries(t.budget.ranges).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-col">
                    <label>{t.budget.timeline}</label>
                    <select
                      value={formData.timeline}
                      onChange={(e) => setFormData({...formData, timeline: e.target.value})}
                    >
                      <option value="">{t.budget.timeline}</option>
                      {Object.entries(t.budget.timelines).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h3>{t.contact.title}</h3>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.contact.name}</label>
                    <input 
                      type="text"
                      value={formData.contact.name}
                      onChange={(e) => setFormData({
                        ...formData,
                        contact: {
                          ...formData.contact,
                          name: e.target.value
                        }
                      })}
                      placeholder={t.contact.namePlaceholder}
                    />
                  </div>
                  <div className="form-col">
                    <label>{t.contact.phone}</label>
                    <input 
                      type="tel"
                      value={formData.contact.phone}
                      onChange={(e) => setFormData({
                        ...formData,
                        contact: {
                          ...formData.contact,
                          phone: e.target.value
                        }
                      })}
                      placeholder={t.contact.phonePlaceholder}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-col">
                    <label>{t.contact.email}</label>
                    <input 
                      type="email"
                      value={formData.contact.email}
                      onChange={(e) => setFormData({
                        ...formData,
                        contact: {
                          ...formData.contact,
                          email: e.target.value
                        }
                      })}
                      placeholder={t.contact.emailPlaceholder}
                    />
                  </div>
                  <div className="form-col">
                    <label>{t.contact.preferredContact}</label>
                    <select
                      value={formData.contact.preferredContact}
                      onChange={(e) => setFormData({
                        ...formData,
                        contact: {
                          ...formData.contact,
                          preferredContact: e.target.value
                        }
                      })}
                    >
                      <option value="">{t.contact.preferredContact}</option>
                      {Object.entries(t.contact.contactMethods).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h3>{t.other.title}</h3>
                <textarea
                  value={formData.additionalRequirements}
                  onChange={(e) => setFormData({...formData, additionalRequirements: e.target.value})}
                  placeholder={t.other.placeholder}
                  rows="4"
                />
              </div>

              {/* 添加提交状态提示 */}
              {submitStatus === 'success' && (
                <div className="submit-status success">
                  表单提交成功！我们会尽快与您联系。
                </div>
              )}
              {submitStatus === 'error' && (
                <div className="submit-status error">
                  提交失败，请稍后重试或直接联系我们。
                </div>
              )}
              
              <button 
                type="submit" 
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? '提交中...' : t.submit}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default WebsiteForm; 