import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './Privacy.css';

function Privacy() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].privacy;

  return (
    <div className="privacy-page">
      <div className="privacy-container">
        <h1 className="privacy-title">{t.title}</h1>
        
        <section className="privacy-section">
          <h2>{t.dataCollection.title}</h2>
          <p>{t.dataCollection.description}</p>
          <div className="info-cards">
            {t.dataCollection.items.map((item, index) => (
              <div key={index} className="info-card">
                <div className="info-icon">📊</div>
                <div className="info-content">
                  <p>{item}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="privacy-section">
          <h2>{t.dataUsage.title}</h2>
          <p>{t.dataUsage.description}</p>
          <div className="usage-grid">
            {t.dataUsage.items.map((item, index) => (
              <div key={index} className="usage-item">
                <span className="usage-icon">✓</span>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="privacy-section">
          <h2>{t.dataSharing.title}</h2>
          <p>{t.dataSharing.description}</p>
          <div className="sharing-list">
            {t.dataSharing.items.map((item, index) => (
              <div key={index} className="sharing-item">
                <span className="sharing-icon">•</span>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="privacy-section">
          <h2>{t.security.title}</h2>
          <div className="security-content">
            <div className="security-icon">🔒</div>
            <p>{t.security.description}</p>
          </div>
        </section>

        <section className="privacy-section">
          <h2>{t.children.title}</h2>
          <div className="children-content">
            <div className="children-icon">👶</div>
            <p>{t.children.description}</p>
          </div>
        </section>

        <section className="privacy-section">
          <h2>{t.changes.title}</h2>
          <div className="changes-content">
            <div className="changes-icon">📝</div>
            <p>{t.changes.description}</p>
          </div>
        </section>

        <footer className="privacy-footer">
          <p>{t.footer}</p>
        </footer>
      </div>
    </div>
  );
}

export default Privacy; 