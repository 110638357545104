import React, { useContext, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../translations';
import './HeroSection.css';

gsap.registerPlugin(ScrollTrigger);

function HeroSection() {
  const { language } = useContext(LanguageContext);
  const t = translations[language].hero;
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  useEffect(() => {
    // 页面加载时立即显示文字
    gsap.fromTo(titleRef.current,
      { opacity: 0, y: 30 },
      { 
        opacity: 1, 
        y: 0, 
        duration: 1,
        delay: 0.2, // 稍微延迟以创造序列感
        ease: "power2.out"
      }
    );

    gsap.fromTo(subtitleRef.current,
      { opacity: 0, y: 30 },
      { 
        opacity: 1, 
        y: 0, 
        duration: 1,
        delay: 0.4, // 在标题之后显示
        ease: "power2.out"
      }
    );

    // 滚动触发的动画
    const scrollTl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 20%", // 更早触发
        end: "center center",
        scrub: 1,
        toggleActions: "play none none reverse"
      }
    });

    return () => {
      scrollTl.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className="hero-section">
      <div className="hero-content">
        <h1 ref={titleRef} className="hero-title">
          {t.title}
        </h1>
        <p ref={subtitleRef} className="hero-subtitle">
          {t.subtitle}
        </p>
      </div>
    </section>
  );
}

export default HeroSection; 