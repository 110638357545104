import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import LanguageModal from './components/LanguageModal';
import HeroSection from './components/HeroSection';
import WebsiteForm from './components/WebsiteForm';
import Features from './components/Features';
import Portfolio from './components/Portfolio';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { LanguageProvider } from './context/LanguageContext';
import LanguageSwitch from './components/LanguageSwitch';
import { Routes, Route } from 'react-router-dom';
import Terms from './components/Terms';
import Cookies from './components/Cookies';
import CookieConsent from './components/CookieConsent';
import Privacy from './components/Privacy';

function App() {
  const logoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const maxScroll = 600;
      const rotation = Math.min(scrolled / maxScroll * 360, 360);

      if (logoRef.current) {
        logoRef.current.style.transform = `rotateY(${rotation}deg) scale(${1 + scrolled / 2000})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // 创建一个主页组件来包含所有需要在首页显示的内容
  const HomePage = () => (
    <>
      <header className="App-header">
        <div className="logo-container">
          <div className="logo-wrapper" ref={logoRef}>
            <div 
              className={`logo-symbol ${isHovered ? 'hovered' : ''}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="cube">
                <div className="cube-face front"></div>
                <div className="cube-face back"></div>
                <div className="cube-face right"></div>
                <div className="cube-face left"></div>
                <div className="cube-face top"></div>
                <div className="cube-face bottom"></div>
              </div>
            </div>
          </div>
          <div className="brand-text">
            <div className="brand-name">
              <div className="domain-name">
                <span className="text-swift">swift</span>
                <span className="text-sites">sites</span>
                <span className="domain-dot">.</span>
                <span className="domain-extension">my</span>
              </div>
            </div>
            <div className="brand-slogan">
              <span className="slogan-text">构建专业网站的智能之选</span>
              <div className="slogan-line"></div>
            </div>
          </div>
        </div>
      </header>
      <HeroSection />
      <WebsiteForm />
      <Features />
      <Portfolio />
      <Pricing />
      <Contact />
    </>
  );

  return (
    <LanguageProvider>
      <div className="App">
        <LanguageModal />
        <LanguageSwitch />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/features" element={<Features />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </div>
    </LanguageProvider>
  );
}

export default App;
