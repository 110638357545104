/* global gtag */  // 声明 gtag 是全局变量

export const CookieManager = {
  // 设置 Cookie
  setCookie: (name, value, days = 365) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  },

  // 获取 Cookie
  getCookie: (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  // 删除 Cookie
  deleteCookie: (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  },

  // 初始化必要的 Cookie
  initEssentialCookies: () => {
    CookieManager.setCookie('essential_cookies', 'true');
    // 添加其他必要的 Cookie
  },

  // 初始化所有 Cookie
  initAllCookies: () => {
    CookieManager.initEssentialCookies();
    // 添加分析 Cookie
    CookieManager.setCookie('analytics_cookies', 'true');
    // 添加营销 Cookie
    CookieManager.setCookie('marketing_cookies', 'true');
    // 初始化 Google Analytics
    if (typeof gtag !== 'undefined') {
      gtag('consent', 'update', {
        'analytics_storage': 'granted',
        'ad_storage': 'granted'
      });
    }
  },

  initMarketingCookies: () => {
    // 启用广告追踪
    if (typeof gtag !== 'undefined') {
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted'
      });
    }
  },

  // 转化追踪
  trackConversion: (conversionId, value = 0, currency = 'MYR') => {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'conversion', {
        'send_to': `AW-XXXXXXXXXX/${conversionId}`,
        'value': value,
        'currency': currency
      });
    }
  },

  // 再营销标签
  trackRemarketing: (params = {}) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'page_view', {
        'send_to': 'AW-XXXXXXXXXX',
        ...params
      });
    }
  }
}; 